import React, { Component, Fragment } from 'react';
import './App.css';
import {
  getContract,
  getMetadataService,
  getProvider
} from 'magazine-utils'
import { AppBar, Typography, Toolbar, Paper, Grid } from '@material-ui/core'

const IPFSLink = ({ path, ipfs }) => <a href={`${ipfs.protocol}://${ipfs.server}:${ipfs.port}${path}`}>{path}</a>
const EthereumAddress = ({ address, etherscan }) =>  <a href={`${etherscan}/address/${address}`}>{address}</a>
const EthereumTransaction = ({ transactionHash, etherscan }) =>  <a href={`${etherscan}/tx/${transactionHash}`}>{transactionHash}</a>
const TockenTrackerLink = ({ address, id, etherscan }) =>  <a href={`${etherscan}/token/${address}?a=${id}`}>#{id}</a>

class App extends Component {
  state = {
    id: null,
    error: null,
    apis : {},
    tokenURI: 'Loading',
    tokenAddress: 'Loading',
    endpoint: 'Loading',
    metadata: null
  }

  config = require('./config.json')

  componentDidMount = () => {
    try {
      const link = window.location.pathname
      const id = link.match(/\/future\/(.*)/)[1]
      this.setState({ id })
      this.loadMagazineData(id)
    } catch(err) {
      window.location.replace('https://riat.at')
    }
  }

  loadMagazineData = async (id) => {
    const ethereum = await getProvider(this.config)
    const metadata = getMetadataService(this.config)
    const magazine = await getContract(ethereum, this.config)

    await this.setState({
      apis: {
        ethereum,
        metadata,
        magazine
      },
      tokenAddress: magazine.options.address,
      endpoint: ethereum.endpoint,
      etherscan: ethereum.etherscan,
      chain: ethereum.network_id
    })

    let tokenURI

    try {
      tokenURI = await magazine.methods.tokenURI(id).call()
      this.setState({ tokenURI })
    } catch(err) {
      this.setState({ error: "invalid token id" })
      return
    }

    let mdata = await metadata.load(tokenURI)

    this.setState({
      metadata: mdata
    })

    if(mdata.block) {
      let events = await magazine.getPastEvents('Transfer', {
        filter: { from: 0, tokenId: id },
        fromBlock: mdata.block, toBlock: parseInt(mdata.block) + 1000
      })

      if(events.length) {
        this.setState({
          transactionHash: events[0].transactionHash
        })
      }
    }
  }

  render() {
    const { id, error, tokenURI, tokenAddress, endpoint, metadata, transactionHash, chain } = this.state

    let content

    let txHashDisplay = !transactionHash
      ? <Typography noWrap variant="body1">Minting transaction not found</Typography>
      : <Typography noWrap variant="body1">Transaction: <EthereumTransaction etherscan={this.state.etherscan} transactionHash={transactionHash}/></Typography>

    let chain_name = 'unknown'

    if(chain === 1) chain_name = 'mainnet'
    else if(chain === 3) chain_name = 'ropsten'

    if(error) {
      content = error
    } else if(!metadata) {
      content =
        <Fragment>
          <Typography noWrap variant="title">Magazine #{id}</Typography>
          <Typography noWrap variant="subtitle1">Metadata URI: <IPFSLink ipfs={this.config.ipfs} path={tokenURI}/></Typography>
          <Typography noWrap variant="body1">Loading Metadata...</Typography>
          <Typography noWrap variant="body1">Ethereum: {endpoint} ({chain_name})</Typography>
          <Typography noWrap variant="body1">Token Address: <EthereumAddress etherscan={this.state.etherscan} address={tokenAddress}/></Typography>
        </Fragment>
    } else {
      content =
        <Fragment>
          <Typography noWrap variant="title">{metadata.name}</Typography>
          <Typography noWrap variant="subtitle2">{metadata.description}</Typography>
          <Typography noWrap variant="subtitle2"><TockenTrackerLink id={id} etherscan={this.state.etherscan} address={tokenAddress}/></Typography>
          <Typography noWrap variant="body1">Quality: {metadata.quality}</Typography>
          {txHashDisplay}
          <Typography noWrap variant="body1">Metadata URI: <IPFSLink ipfs={this.config.ipfs} path={tokenURI}/></Typography>
          <Typography noWrap variant="body1">Photo URI: <IPFSLink ipfs={this.config.ipfs} path={metadata.image}/></Typography>
          <Typography noWrap variant="body1">Ethereum: {endpoint} ({chain_name})</Typography>
          <Typography noWrap variant="body1">Token Address: <EthereumAddress etherscan={this.state.etherscan} address={tokenAddress}/></Typography>
          <img src={`${this.config.ipfs.protocol}://${this.config.ipfs.server}:${this.config.ipfs.port}/${metadata.image}`} alt="magazine" style={{ maxWidth: '100%' }}/>
        </Fragment>
    }

    return (
      <div className="App">
        <AppBar position="static" style={{ background: '#f8f6f5' }}>
          <Toolbar>
            <a href="https://riat.at"><img src="/RIAT.svg" alt="riat logo" height="50px"/></a>
            <a href="https://riat.at"><Typography variant="body1" className="AppBarEntry">RIAT Future Cryptoeconomics</Typography></a>
          </Toolbar>
        </AppBar>
        <Grid container justify="center">
          <Grid item xs zeroMinWidth>
            <Paper className="content">
              {content}
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default App;
